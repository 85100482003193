<template>
    <div class="navigationBar">
        <img class="colorizeOnHover" src="../assets/keuken_logo.webp" alt="">
    </div>
</template>

<script>
export default {
    name: "NavigationBar",
}
</script>

<style lang="scss">
@import "../scss/general/variables";

.navigationBar {
    background-color: lighten($color: $darkGray, $amount: 3);
    height: 100vh;
    width: $navigationBarWidth;
    position: fixed;
    box-shadow: 3px 0px 10px rgba(0, 0, 0, .3);
    z-index: 1000;

    padding: 50px 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    img {
        transform: rotate(-90deg);
        margin-bottom: 140px;
        height: 50px;
    }
}

@media screen and (max-width: 1060px) {    
    .navigationBar {
        height: $navigationBarHeight;
        width: 100vw;

        flex-direction: row;
        padding: 0 $sectionPadding;

        justify-content: center;

        img {
            transform: rotate(0deg);
            margin-bottom: 0;
            height: 40px;
        }
    }
}
</style>