<template>
    <div class="contact section splitted free dark" id="contactSection">
        <div class="left map">
            <iframe rel="preload" title="Mapa hacia Keuken Herrajes, provisto por Google Maps" class="map" 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6580.61313748603!2d-58.78737389999999!3d-34.444365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bc9ed76a803cf7%3A0xcd307eeaed002fe6!2sKeuken%20herrajes!5e0!3m2!1ses!2sar!4v1605893526905!5m2!1ses!2sar" 
            width="100%" height="100%" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"/>
        </div>

        <div class="right panel">
            <div class="content">
                <Title tag="Contacto">¡Te estamos esperando para ayudarte!</Title>
                <p class="text">
                    Estamos ansiosos por conocerte y ayudarte a cubrir todas tus necesidades, para que tu 
                    final de obra concluya con la mejor experiencia. Nuestro equipo de trabajo está listo para
                    asesorarte sobre todo lo que se refiere al mundo del herraje, optimizando tus tiempos con 
                    nuestra asesoría personalizada en tu obra y la entrega del producto donde prefieras.
                </p>

                <div class="social">
                    <h3>Nuestras redes sociales</h3>
                    <SocialMediaBar/>
                </div>
                <hr>
                <div class="phoneNumbers">
                    <p>Keuken Central: 02320-404110</p>
                    <p>WhatsApp Online: +54 9 11 5620-6400</p>
                    <p>WhatsApp Servicios: +54 9 11 6186-4181</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SocialMediaBar from '../socialMediaBar.vue'
import Title from '../title.vue'

export default {
    name: "Contact",
    components: {
        Title,
        SocialMediaBar,
    }
}
</script>

<style lang="scss">
@import "../../scss/general/variables";

.contact {
    background: $darkGray;

    .map {
        iframe {
            filter: grayscale(1) contrast(83%) invert(92%);
        }
    }

    .right {
        .content {
            display: flex;
            flex-direction: column;
            align-content: center;

            p.text {
                margin: 50px 0;
            }
            
            .social {
                flex-direction: row;
                display: flex;
                justify-content: space-between;

                @media screen and (max-width: 600px) {
                    flex-direction: column;

                    h3 {
                        margin-bottom: 20px;
                    }
                }
            }

            hr {
                margin: 20px 0;
            }

            .phoneNumbers {
                font-size: .8rem;
            }
        }
    }
}

@media screen and (max-width: 1330px) {
    .contact.splitted {
        flex-direction: column;

        .left, .right {
            width: 100%;
        }

        .left {
            iframe {
                min-height: 40vh;
            }
        }

        .right {
            min-height: 60vh;
        }
    }
}
</style>