<template>
    <div class="products panel dark" id="productsSection">
        <div class="content splitted">
            <div class="left">
                <Title tag="Productos">Innovación para un<br>mundo a medida</Title>
            </div>

            <div class="right">
                <p>
                    Llevamos <keuken-clock class="keukenClock colorizeOnHover"/> 
                    buscando darte el mejor servicio junto a la búsqueda en el perfeccionamiento del mismo.
                </p>
                <p>
                    Tenemos una amplia gama de productos necesarios para llevar tus instalaciones
                    al siguiente nivel, siendo proveedores de las últimas novedades del mercado y otorgando
                    una atención y una guía de calidad.
                </p>
            </div>
        </div>

        <vue-glide ref="slider" class="cards" :options="glideOptions">
            <vue-glide-slide v-for="(slide, index) in slides" :key="index">
                <unframed-card :title="slide.title" :imageURL="slide.imageURL"/>
            </vue-glide-slide>
        </vue-glide>
    </div>
</template>

<script>
import {Glide, GlideSlide} from 'vue-glide-js';
import "vue-glide-js/dist/vue-glide.css";
import Title from '../title.vue';
import UnframedCard from '../unframedCard.vue';
import KeukenClock from '../keukenClock.vue';

export default {
    name: "Products",
    components: {
        UnframedCard,
        [Glide.name]: Glide,
        [GlideSlide.name]: GlideSlide,
        Title,
        KeukenClock,
    },
    data() {
        return {
            glideOptions: {
                autoplay: true,
                animationDuration: 4000,
                type: "carousel",
                perView: 2.2,
                breakpoints: {
                    1050: {
                        autoplay: false,
                        animationDuration: 300,
                        perView: 1.1
                    }
                }
            },
            slides: [
                {
                    imageURL: require("@/assets/products/product_card_2.webp"),
                    title: "Accesorios para baños, cocinas y vestidores",
                },
                {
                    imageURL: require("@/assets/products/product_card_3.webp"),
                    title: "Sistemas de división plegadizos y corredizos"
                },
                {
                    imageURL: require("@/assets/products/product_card_1.webp"),
                    title: "Herrajes varios"
                },
                {
                    imageURL: require("@/assets/products/product_card_4.webp"),
                    title: "Cerraduras"
                },
                
            ],
        };
    },
}
</script>

<style lang="scss">
@import "../../scss/general/variables";

.products {
    background: $darkGray;
    
    .content {
        margin-bottom: 100px;

        .titleWithTag {
            h2, p {
                color: #fff;
            }
        }

        .right {
            .keukenClock {
                color: $primaryColor;
                border-bottom: .5px solid $primaryColor;
                font-weight: 400;
            }
        }
    }

    .cards {
        .unframedCard {
            margin-right: 100px!important;
            
            p {
                color: $primaryColor;
            }
        }
    }

    
    @media screen and (max-width: 1050px) {
        .content.splitted {
            margin-bottom: 50px;
            flex-direction: column;
            
            
            .left, .right {
                width: 100%;
            }

            .left {
                margin-bottom: 20px;
            }
        }
        .cards {
            .unframedCard {
                margin-right: 20px!important;
            
                p {
                    color: $primaryColor;
                }
            }
        }
    }
}
</style>