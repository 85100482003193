<template>
    <span>
        {{getTextForCounter(dateDistance.years(), "año", "años")}},
        {{getTextForCounter(dateDistance.months(), "mes", "meses")}},
        {{getTextForCounter(dateDistance.days(), "día", "días")}},
        {{getTextForCounter(dateDistance.hours(), "hora", "horas")}},
        {{getTextForCounter(dateDistance.minutes(), "minuto", "minutos")}} y
        {{getTextForCounter(dateDistance.seconds(), "segundo", "segundos")}},
    </span>
</template>

<script>
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export default {
    name: "KeukenClock",
    data() {
        return {
            now: dayjs(),
            objetive: dayjs("2001-10-01 08:00:00"),
            
            tick: null,
        }
    },
    mounted() {
        this.tick = setInterval( ()=>{
            this.now = dayjs();
        }, 1000)
    },
    computed: {
        dateDistance() {
            return dayjs.duration(this.now.diff(this.objetive));
        },
    },
    methods: {
        getTextForCounter(unit, singular, plural) {
            let stringToReturn = (unit + " ")

            if (unit > 1 || unit == 0) {
                stringToReturn += plural
            } else {
                stringToReturn += singular
            }

            return stringToReturn
        }
    }
}
</script>