<template>
  <body>
    <navigation-bar data-aos="fade" data-aos-duration="2000"/>

    <main>
      <home/>
      <about/>
      <products/>
      <mercado-libre/>
      <services/>
      <contact/>
      <end/>
    </main>
  </body>
</template>

<script>
import About from './components/sections/about.vue';
import Contact from './components/sections/contact.vue';
import Home from './components/sections/home.vue';
import MercadoLibre from './components/sections/mercadoLibre.vue';
import Products from './components/sections/products.vue';
import Services from './components/sections/services.vue';
import End from './components/sections/footer.vue';
import NavigationBar from './components/navigationBar.vue';


export default {
  name: 'App',
  components: {
    Home,
    About,
    Products,
    MercadoLibre,
    Services,
    Contact,
    End,
    NavigationBar,
  },
};
</script>

<style lang="sass">
@import './scss/styles'


</style>
