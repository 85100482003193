<template>
    <div class="services section splitted free" id="servicesSection">
        <div class="left banner"></div>

        <div class="right panel">
            <div class="content">
                <Title tag="Keuken Servicios">Nuestra ayuda en tu hogar</Title>

                <p class="text">
                    Brindamos todo tipo de soluciones referidas a las necesidades de tu hogar,
                    poniendo a tu disposición todos nuestros productos y servicios dentro del
                    marco de una experiencia laboral única.
                </p>

                <div class="icons">
                                            
                    <div class="item colorizeOnHover">
                        <ToolIcon class="icon"/>
                        <p class="description">Instalación de herrajes</p>
                    </div>

                    <div class="item colorizeOnHover">
                        <ShieldIcon class="icon"/>
                        <p class="description">Mejoramos la seguridad de tu hogar</p>
                    </div>

                    <div class="item colorizeOnHover">
                        <ZapIcon class="icon"/>
                        <p class="description">Ajustamos tus muebles</p>
                    </div>
                </div>

                <button class="primary"><a target="_blank" href="https://api.whatsapp.com/send?phone=5411961864181">Quiero hablar con un técnico</a></button>
            </div>
        </div>
    </div>
</template>

<script>
import Title from '../title.vue'

import {ToolIcon, ShieldIcon, ZapIcon} from 'vue-feather-icons';

export default {
    name: "services",
    components: {
        Title,
        ToolIcon,
        ShieldIcon,
        ZapIcon,
    }
}
</script>

<style lang="scss">
@import "../../scss/general/variables";

.services {
    background: $strangeWhite;

    .right {
        .content {
            display: flex;
            flex-direction: column;
            align-content: center;

            p.text {
                margin-top: 50px;
            }
            
            p.text, .icons {
                margin-bottom: 50px;
            }

            .icons {
                display: flex;
                text-align: center;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                
                .item {
                    margin: 20px;
                    cursor: pointer;
                    width: 25%;

                    .icon {
                        width: 100px;
                        height: 60px;
                        stroke: $primaryColor;
                    }

                    p.description {
                        font-weight: 400;
                    }

                    @media screen and (max-width: 650px) {
                        min-width: 300px;
                    }
                }
            }

            button.primary {
                margin: auto;
                width: 80%;

                @media screen and (max-width: 650px) {
                    width: 100%;
                }
            }
        }
    }

    .banner {
        background-image: url(../../assets/services_banner.jpeg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}

@media screen and (max-width: 1330px) {
    .services.splitted {
        flex-direction: column;

        .left, .right {
            width: 100%;
        }

        .left {
            min-height: 30vh;
        }

        .right {
            min-height: 70vh;
        }
    }
}
</style>