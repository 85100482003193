<template>
    <div class="socialMediaBar">
        <a title="Nuestro Facebook" target="_blank" href="https://www.facebook.com/pg/Keuken-Herrajes-615753928777813">
            <FacebookIcon class="icon colorizeOnHover"/>
        </a>
        <a title="Nuestro Instagram" target="_blank" href="https://www.instagram.com/keukenherrajes">
            <InstagramIcon class="icon colorizeOnHover"/>
        </a>
        <a title="Nuestro WhatsApp" target="_blank" href="https://api.whatsapp.com/send?phone=5491161864181">
            <PhoneIcon class="icon colorizeOnHover"/>
        </a>
        <a title="Nuestro correo electrónico" target="_blank" href="mailto: ventas@keukenherrajes.com.ar">
            <MailIcon class="icon colorizeOnHover"/>
        </a>
    </div>
</template>

<script>
import {FacebookIcon, InstagramIcon, MailIcon, PhoneIcon} from 'vue-feather-icons';

export default {
    name: "SocialMediaBar",
    components: {
        FacebookIcon,
        InstagramIcon,
        MailIcon,
        PhoneIcon,
    }
}
</script>

<style lang="scss">
@import "../scss/general/variables";

.socialMediaBar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    a {
        margin-right: 20px;

        .icon {
            cursor: pointer;
            stroke: $primaryColor;
        }
    }
}
</style>