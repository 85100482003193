<template>
    <div class="unframedCard colorizeOnHover">
        <div v-if="imageURL" class="image" :style="{ backgroundImage: `url(${imageURL})` }"></div>
        <p>{{title}}</p>
    </div>
</template>

<script>
export default {
    name: "UnframedCard",
    props: ["imageURL", "title"],
}
</script>

<style lang="scss">
.unframedCard {
    cursor: pointer;

    .image {
        min-height: 200px;
        width: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}
</style>