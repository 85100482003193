import Vue from 'vue';
import App from './App.vue';

import AOS from 'aos';
import 'aos/dist/aos.css';

import VueScrollTo from 'vue-scrollto';

AOS.init();
Vue.use(VueScrollTo, {duration: 1000, offset: -100});

Vue.config.productionTip = false;

new Vue({
  created () {
    AOS.init()
  },
  render: (h) => h(App),
}).$mount('#app');
