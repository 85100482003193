<template>
    <div class="contentBox">
        <h3 class="colorizeOnHover" v-if="title">{{title}}</h3>
        <hr v-if="title">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "ContentBox",
    props: ["title"],
}
</script>

<style lang="scss">
@import "../scss/general/variables";

.contentBox {
    h3 {
        font-weight: 400;
        color: $primaryColor;
    }
    hr {
        margin: 10px 0;
    }
}
</style>