<template>
    <div class="panel footer">
        <img src="../../assets/keuken_logo.webp" class="colorizeOnHover" alt="">
        <p>Av.Constitución 491, Del Viso (Bs.As, Argentina)</p>
        <p>Lunes a viernes (08:00hs - 18:00hs) | Sábados (08:00hs - 13:00hs)</p>
        <p>ventas@keukenherrajes.com.ar</p>
        <p>02320-404110</p>
    </div>
</template>

<script>
export default {
    name: "end",
    components: {  },
}
</script>

<style lang="scss">
@import "../../scss/general/variables";

.footer {
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    p {
        font-size: .8rem;
        text-align: center;
    }

    img {
        width: 30%;
        min-width: 360px;
    }
}
</style>