<template>
    <div class="titleWithTag">
        <p v-if="tag" class="tag">{{tag}}</p>
        <h2><slot></slot></h2>
    </div>
</template>

<script>
export default {
    name: "TitleWithTag",
    props: ["title", "tag"],
}
</script>

<style lang="scss">
.titleWithTag {
    .tag {
        font-size: .8rem;
        opacity: .8;
        text-transform: uppercase;
    }

    h2 {
        font-size: 2.6rem;
    }
}
</style>