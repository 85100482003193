<template>
    <div class="panel section about" id="aboutSection">
        <div class="text splitted">
            <TitleWithTag class="left" tag="¿Quénes somos?">
                Nosotros,<br/>
                <span class="colorizeOnHover">Keuken Herrajes</span>
            </TitleWithTag>

            <p class="right">Somos una empresa confiable en constante crecimiento con un objetivo claro: Ofrecer soluciones con la mas amplia variedad de productos del mercado para nuestros clientes llevando a cabo sus proyectos de forma exitosa junto a un equipo capacitado, amable y comprometido.</p>
        </div>

        <div class="contentBoxes">
            <ContentBox title="Nuestros valores">
                <p>
                    Trabajo en equipo, comunicación, compromiso, responsabilidad, respeto, 
                    honestidad, integridad, calidad y orden.
                </p>
            </ContentBox>

            <ContentBox title="Nuestra misión">
                <p>
                    Estar en continuo crecimiento personal para mejorar cada día,
                    mediante un equipo de trabajo amable y comprometido, algo que
                    nos vuelve una empresa ordenada, organizada, dinámica y activa.
                </p>
            </ContentBox>

            <ContentBox title="Nuestra visión">
                <p>
                    Ser una empresa con espíritu libre, enfocada en crecer sin límites porque 
                    la libertad nos inspira a cruzar todas las fronteras y abrir nuevos caminos.
                </p>
            </ContentBox>
        </div>
    </div>
</template>

<script>
import ContentBox from '../contentBox.vue'
import TitleWithTag from '../title.vue'
export default {
    name: "about",
    components: { TitleWithTag, ContentBox },
}
</script>

<style lang="scss">
@import "../../scss/general/variables";

.about {
    background: #fff;
    flex-direction: column!important;
    display: flex!important;
    justify-content: space-evenly;

    .text {
        .titleWithTag, p {
            width: 50%;
        }

        .titleWithTag {
            h2 span {
                color: $primaryColor;
                font-weight: 400;
            }
        }

        margin-bottom: 100px;
    }

    .contentBoxes {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        .contentBox {
            min-width: 400px;
            flex-wrap: wrap;
            width: 30%;
            margin: 0 20px;
        }
    }

    @media screen and (max-width: 1050px) {
        .text.splitted {
            flex-direction: column;
            margin-bottom: 20px;
            
            .left, .right {
                width: 100%;
            }

            .left {
                margin-bottom: 20px;
            }
        }

        .contentBoxes {
            justify-content: center;

            .contentBox {
                margin: 30px;
            }
        }
    }

    @media screen and (max-width: 1000px) {
        text-align: center;

        .titleWithTag {
            p.tag {
                width: 100%!important;
            }
        }

        .contentBoxes {
            justify-content: center;

            .contentBox {
                margin: 30px;
            }
        }
    }
}
</style>