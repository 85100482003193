<template>
    <div class="home section splitted" data-aos="fade" data-aos-duration="1800" id="homeSection">
        <div class="left panel">
            <div class="content">
                <h1>Innovamos tus ideas y damos vida a tus proyectos</h1>
                <p>
                    Tus proyectos necesitan de materiales de alta calidad como de una atención experimentada y
                    totalmente a disposición de tu creatividad. ¿Cómo te podemos ayudar?
                </p>
                <div class="buttons">
                    <button class="primary" v-scroll-to="'#productsSection'">Ver productos</button>
                    <button v-scroll-to="'#contactSection'">Contactar</button>
                </div>

                <SocialMediaBar/>
            </div>
        </div>

        <div class="right banner"></div>
    </div>
</template>

<script>
import SocialMediaBar from '../socialMediaBar';

export default {
    name: "home",
    components: {
        SocialMediaBar,
    }
}
</script>

<style lang="scss">
@import "../../scss/styles.scss";

.home {
    background: $strangeWhite;
    .left {
        display: flex;
        justify-content: center;
        align-items: center;

        .content {
            h1 {
                font-size: 3rem;
            }

            p {
                margin: 40px 0;
            }

            .buttons {
                button.primary {
                    margin-right: 40px;
                }
            }

            .socialMediaBar {
                margin-top: 30px;
            }
        }
    }    

    .banner {
        background-image: url(../../assets/home_banner.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}

@media screen and (max-width: 1330px) {
    .home.splitted {
        flex-direction: column-reverse;

        .left, .right {
            width: 100%;
        }

        .left {
            min-height: 70vh;
        }

        .right {
            min-height: 30vh;
        }
    }
}

@media screen and (max-width: 1000px) {
    .home.splitted {
        text-align: center;

        .buttons {
            button {
                width: 100%;
            }

            button.primary {
                margin-bottom: 20px;
            }
        }

        .socialMediaBar {
            width: 100%;
            justify-content: space-between;
        }
    }
}
</style>