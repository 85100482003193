<template>
    <div class="mercadoLibre" id="mercadoLibreSection">
        <div class="content1 splitted panel">
            <div class="left">
                <img src="../../assets/ml_logo.png" alt="">
            </div>

            <div class="right">
                <Title tag="MercadoLibre">Encontranos en<br>MercadoLibre</Title>
                <div class="bottom">
                    <button class="primary"><a href="https://www.tiendakeuken.com.ar/" target="_blank">¡Quiero conocerlos!</a></button>
                    <img src="../../assets/mle_logo.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Title from '../title';

export default {
    name: "MercadoLibre",
    components: {
        Title, 
    },
}
</script>

<style lang="scss">
@import "../../scss/general/variables";

.mercadoLibre {
    .content1 {
        background-color: #fff;
        align-items: center;

        .left, .right {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        .left {
            align-items: center;
            img {
                max-width: 340px;
                min-width: 200px
            }
        }

        .right {
            .bottom {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 20px;

                img {
                    margin: 0 50px;
                }
            }

            @media screen and (max-width: 1560px) {
                .bottom {
                    align-items: flex-start;
                    flex-direction: column;

                    img {
                        margin: 0;
                        margin-top: 20px;
                        width: 40%;
                    }
                }
            }

            @media screen and (max-width: 1330px) {
                button {
                    width: 100%;
                }
            }
        }

        
        @media screen and (max-width: 1050px) {
            flex-direction: column!important;

            .left, .right {
                text-align: center;
                width: 100%;
            }

            .left {
                img {
                    width: 30%;
                    margin-bottom: 50px;
                }
            }

            .right {
                align-items: center;

                .bottom {
                    width: 50%;
                    margin-top: 50px;
                    align-items: center;
                }
            }
        }
        
        @media screen and (max-width: 1050px) {
            flex-direction: column!important;

            .left, .right {
                text-align: center;
                width: 100%;
            }

            .left {
                img {
                    width: 30%;
                    margin-bottom: 50px;
                }
            }

            .right {
                align-items: center;

                .bottom {
                    width: 100%;
                    margin-top: 50px;
                    align-items: center;
                }
            }
        }
    }
}
</style>